@tailwind base;
@tailwind components;
@tailwind utilities;

/*
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background-color: rgb(244, 244, 244);
  font-family: 'Roboto'; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1, h2, h3, h4, h5, h6{
  font-family: 'Roboto';
  font-weight: 500;
}

h1{
  font-size: 32px;
}

h2{
  font-size: 27px;
}

h3{
  font-size: 24px;
}

h4{
  font-size: 21px;
}

html, body, p, ul, li
{
  font-size: 16px;
}

.wrapperx
{
  box-sizing: border-box;
  background: -moz-linear-gradient(-45deg,  rgba(255,255,255,1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: linear-gradient(135deg,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}

.author-name{
  font-size: 46px;
  line-height: 60px;
  font-weight: bold;
  margin-left: -5px;
}

.author-spelling{
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 30px;
}

.author-description{
  padding: 5px 0 20px 0;
  font-size: 18px;
  font-family: 'Roboto';
}

.project-description > p
{
  margin-bottom: 25px;
}

.skill-list
{
  flex-wrap: wrap;
  display: flex;
  justify-content: space-evenly;
}
.skill-item
{
  padding: 0 10px 6px 0;
  margin: 0 5px 5px 0;
  display: flex;
  justify-content:center;
}
.skill-item i
{

  margin: 0 10px;
  font-size: 1.4rem;
}

img.splash-image
{
  max-width: 260px;
  margin: auto;
}

/* slider */
.slick-slider
{
  padding: 20px 0;
  margin: 30px 0;
}


.header {
  background: linear-gradient(60deg, rgb(251, 255, 0) 0%, rgb(156, 173, 0) 100%);
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}
/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}